import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './components/home/Home';
import Routes from './components/routing/Routes';
import Footer from './components/layout/Footer';

import './App.css';

import under_construction from './photography/under_construction.png';

const App = () => (
  <Fragment>
    <Router>
      <Navbar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route component={Routes} />
      </Switch>
      <Footer />
    </Router>
  </Fragment>
);

export default App;

import React, { Fragment } from 'react';

const Footer = () => {
  return (
    <Fragment>
      <section className='footer'>
        <p className='text-primary'>
          Follow <a href='https://www.instagram.com/kweerd/'>@kweerd</a> for
          updates
        </p>
        <p className='small'>
          <span>&#169;</span>2021 KWEERD, LLC
        </p>
      </section>
    </Fragment>
  );
};

export default Footer;

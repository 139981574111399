import React from 'react';

import under_construction from '../../photography/under_construction.png';

const Jacklyn = () => {
  return (
    <section className='main'>
      <h1 className='large'>
        {/* <i class='fas fa-rainbow'></i> <br></br> */}
        under construction
      </h1>
      <img
        src={under_construction}
        alt='under construction'
        className='banner'
      />
    </section>
  );
};

export default Jacklyn;

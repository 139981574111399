import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Jacklyn from '../jacklyn/Jacklyn';
import Gallery from '../gallery/Gallery';
import Tattoo from '../tattoo/Tattoo';
import Shop from '../shop/Shop';
import Contact from '../contact/Contact';

const Routes = () => {
  return (
    <section className='main'>
      <Switch>
        <Route exact path='/jacklyn' component={Jacklyn} />
        <Route exact path='/gallery' component={Gallery} />
        <Route exact path='/tattoo' component={Tattoo} />
        <Route exact path='/Shop' component={Shop} />
        <Route exact path='/Contact' component={Contact} />
      </Switch>
    </section>
  );
};

export default Routes;

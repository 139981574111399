import React from 'react';
import { Link } from 'react-router-dom';

import kweerdblack10000 from '../../logos/kweerdblack10000.png';

const Navbar = () => {
  return (
    <nav className='navbar'>
      <Link to='/'>
        <img src={kweerdblack10000} alt='kweerd logo' className='logo'></img>
      </Link>
      <ul>
        <li className='item text-primary inactive-link'>Jacklyn</li>
        <li className='item text-primary inactive-link'>Gallery</li>
        <li className='item text-primary inactive-link'>Tattoo</li>
        <li className='item text-primary inactive-link'>Shop</li>
        <li className='item text-primary inactive-link'>Contact</li>
      </ul>
    </nav>
  );
};

export default Navbar;
